import { createSlice } from "@reduxjs/toolkit";
import { resetStateAction } from "../State/resetStateMiddleware";

const initialState = {
  //  fullName: null,
  sponsaUserName: null,
  token: null,
  userImg: null,
  password: null,
  email: null,
  accountType: null,
  displayName: null,
  bio: null,
  userId: null,
  api_key: null,
  uid: null,
  company: null,
  logoutTimer: null,
  YtUrl: null,
  TwitterUrl: null,
  InstaUrl: null,
  FbUrl: null,
  CoverIMG: null,
  stripeID: null,
  stripeConnectionDate: null,
  stripePublishableKey: null,
  role: null,
  IsGoogleAccount: null,
  GoogleID: null,
  stripeConnectedEmail: null,
  currency: null,
  isverified: false,
};

export const authSlice = createSlice({
  name: "auth",
  initialState: initialState,

  reducers: {
    setSignUpdata: (state, action) => {
      //  state.fullName = action.payload.UserName;
      const email = action.payload.email;
      state.email = email;
      const password = action.payload.password;
      state.password = password;
      state.IsGoogleAccount = action.payload.IsGoogleAccount;
      state.GoogleID = action.payload.GoogleID;
      state.accountType = action.payload.accountType;
      state.sponsaUserName = action.payload.sponsaUserName;
      state.userImg = action.payload.userImg;
      state.displayName = action.payload.displayName;
      state.bio = action.payload.bio;
    },
    setAccountUpdateData: (state, action) => {
      state.sponsaUserName = action.payload.sponsaUserName;
      state.userImg = action.payload.userImg;
      state.displayName = action.payload.displayName;
      state.bio = action.payload.bio;
      state.YtUrl = action.payload.YtUrl;
      state.TwitterUrl = action.payload.TwitterUrl;
      state.InstaUrl = action.payload.InstaUrl;
      state.FbUrl = action.payload.FbUrl;
      state.CoverIMG = action.payload.CoverIMG;
    },
    setAccountType: (state, action) => {
      state.accountType = action.payload;
    },
    setCurrency: (state, action) => {
      state.currency = action.payload;
    },
    // setStripeID: (state, action) => {
    //   state.stripeID = action.payload.stripeID;
    //   state.stripeConnectionDate = action.payload.stripeConnectionDate;
    //   state.stripePublishableKey = action.payload.stripePublishableKey;
    // },
    setStripeID: (state, action) => {
      state.stripeID = action.payload.stripeID;
      state.stripeConnectionDate = action.payload.connectionDate;
      state.stripePublishableKey = action.payload.publishableKey;
      state.stripeConnectedEmail = action.payload.stripeConnectedEmail;
    },
    resetStripeID: (state) => {
      state.stripeID = null;
      state.stripeConnectionDate = null;
      state.stripePublishableKey = null;
      state.stripeConnectedEmail = null;
    },
    setSignUpImg: (state, action) => {
      const userImg = action.payload.userImg;
      state.userImg = userImg;
    },

    setEmail: (state, action) => {
      state.email = action.payload.email;
    },
    setCoverImage: (state, action) => {
      state.CoverIMG = action.payload.CoverIMG;
    },
    setuid: (state, action) => {
      state.uid = action.payload.uid;
    },

    setLogin: (state, action) => {
      //  state.fullName = action.payload.username;
      state.email = action.payload.email;
      state.accountType = action.payload.accountType;
      state.sponsaUserName = action.payload.sponsaUserName;
      state.displayName = action.payload.displayName;
      state.uid = action.payload.uid;
      state.userImg = action.payload.userImg;
      state.YtUrl = action.payload.YtUrl;
      state.TwitterUrl = action.payload.TwitterUrl;
      state.InstaUrl = action.payload.InstaUrl;
      state.FbUrl = action.payload.FbUrl;
      state.CoverIMG = action.payload.CoverIMG;
      state.bio = action.payload.bio;
      state.stripeID = action.payload.stripeID;
      state.stripeConnectionDate = action.payload.stripeConnectionDate;
      state.stripePublishableKey = action.payload.stripePublishableKey;
      state.currency = action.payload.currency;
      state.isverified = action.payload.isverified;
      state.role = action.payload.role;
      sessionStorage.setItem("authToken", action.payload.uid);
      localStorage.setItem("authToken", action.payload.uid);
    },

    setLogoutTimer: (state, action) => {
      // Update the logout timer in the state
      state.logoutTimer && clearTimeout(state.logoutTimer);
      state.logoutTimer = action.payload;
    },

    setLogOut: (state) => {
      //  state.fullName = null;
      state.token = null;
      state.email = null;
      state.userId = null;
      state.api_key = null;
      state.uid = null;
      state.company = null;
      state.accountType = null;
      state.password = null;
      state.stripeID = null;
      state.stripeConnectionDate = null;
      state.stripePublishableKey = null;
      state.currency = null;
      state.isverified = false;
      state.role = null;
      sessionStorage.removeItem("authToken");
      localStorage.removeItem("authToken");
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setSignUpdata,
  setSignUpImg,
  setLogin,
  setLogOut,
  setLogoutTimer,
  setEmail,
  setuid,
  setAccountUpdateData,
  setCoverImage,
  setAccountType,
  setStripeID,
  resetStripeID,
  setCurrency,
} = authSlice.actions;

export default authSlice.reducer;
export { resetStateAction };
